<template>
  <div class="page bg-white" id="reorganize">
    <div class="tabsCls">
      <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contens">
      <div v-show="selectTab == '999'">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="200px"
                 size="small">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="鉴定时间" prop="appraisalDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="inputForm.appraisalDate"
                    type="date"
                    :disabled="inputType"
                    clearable
                    placeholder="请选择鉴定时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="鉴定机构" prop="appraisalInstitution">
                <el-input
                    v-model.trim="inputForm.appraisalInstitution"
                    placeholder="请输入鉴定机构(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="经手人" prop="agent">
                <el-input
                    v-model.trim="inputForm.agent"
                    placeholder="请输入经手人(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="专家姓名" prop="collectionAppraisalExpert">
                <el-select
                    style="width: 100%;"
                    v-model="inputForm.collectionAppraisalExpert"
                    multiple
                    :multiple-limit="10"
                    filterable
                    remote
                    :disabled="inputType"
                    reserve-keyword
                    placeholder="请选择专家姓名"
                    @focus="remoteMethod"
                    :remote-method="remoteMethod"
                    :loading="expertNameLoading">
                  <el-option
                      v-for="item in expertNameList"
                      :key="item.expertId"
                      :label="item.expertName"
                      :value="item.expertId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="鉴定类型" prop="appraisalTypes">
                <el-select
                    v-model="inputForm.appraisalTypes"
                    clearable multiple
                    :disabled="inputType"
                    placeholder="请选择鉴定类型"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('appraisal_type')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--                        <el-col :span="22">-->
            <!--                            <el-form-item label="馆外专家" prop="dynamicTags">-->
            <!--                                <el-tag-->
            <!--                                        :key="tag"-->
            <!--                                        size="medium"-->
            <!--                                        v-for="tag in inputForm.dynamicTags"-->
            <!--                                        closable-->
            <!--                                        :disable-transitions="false"-->
            <!--                                        @close="handleClose(tag)">-->
            <!--                                    {{ tag }}-->
            <!--                                </el-tag>-->
            <!--                                <el-input-->
            <!--                                        class="input-new-tag"-->
            <!--                                        v-if="inputVisible"-->
            <!--                                        v-model="inputValue"-->
            <!--                                        ref="saveTagInput"-->
            <!--                                        size="small"-->
            <!--                                        placeholder="请输入馆外专家(限20字)"-->
            <!--                                        maxlength="20"-->
            <!--                                        @keyup.enter.native="handleInputConfirm"-->
            <!--                                        @blur="handleInputConfirm"-->
            <!--                                >-->
            <!--                                </el-input>-->
            <!--                                <el-button v-else :disabled="inputForm.dynamicTags.length == 10" class="button-new-tag"-->
            <!--                                           size="small" @click="showInput">+ 新增专家-->
            <!--                                </el-button>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <el-col :span="22">
              <el-form-item label="鉴定方法" prop="appraisalMethod">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.appraisalMethod" maxlength="200"
                          placeholder="请输入鉴定方法(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="申请原因" prop="reason">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.reason" maxlength="500"
                          placeholder="请输入鉴定的申请原因(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="100"
                          placeholder="其它需要特别说明的事项或信息(限100字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="selectTab == '1'">
        <el-form size="small" ref="inputForm" inline :model="search1" label-width="100px">
          <el-form-item label="藏品名称" prop="zzName">
            <el-input v-model.trim="search1.zzName"
                      placeholder="请输入藏品名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="藏品编号" prop="zzId">
            <el-input v-model.trim="search1.zzId"
                      placeholder="请输入藏品编号(限50字)"
                      maxlength="50"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(1)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(1)">
              <i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search1.total }}件藏品</div>
        <el-table
            :data="solicitationList"
            size="small"
            style="margin-left: 20px"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            v-loading="search1.loading"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable1"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <!--                            identified-->
              <el-button v-show="scope.row.identified == '否'" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">新增鉴定结果
              </el-button>
              <el-button v-show="scope.row.identified == '是'" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">查看鉴定结果
              </el-button>
              <el-button size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search1.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search1.size"
              :total="search1.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>

      <div v-show="selectTab == '2'">
        <el-form size="small" ref="inputForm" inline :model="search2" label-width="100px">
          <el-form-item label="藏品名称" prop="zzName">
            <el-input v-model.trim="search2.zzName"
                      placeholder="请输入藏品名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="藏品编号" prop="zzId">
            <el-input v-model.trim="search2.zzId"
                      placeholder="请输入藏品编号(限50字)"
                      maxlength="50"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(2)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(1)"><i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search2.total }}件藏品</div>
        <el-table
            :data="generalList"
            size="small"
            style="margin-left: 20px"
            v-loading="search2.loading"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable2"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button v-show="scope.row.identified == '否'" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">新增鉴定结果
              </el-button>
              <el-button v-show="scope.row.identified == '是'" style="margin-bottom: 10px" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">查看鉴定结果
              </el-button>
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search2.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search2.size"
              :total="search2.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>

      <div v-show="selectTab == '3'">
        <el-form size="small" ref="inputForm" inline :model="search3" label-width="100px">
          <el-form-item label="藏品名称" prop="zzName">
            <el-input v-model.trim="search3.zzName"
                      placeholder="请输入藏品名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="藏品编号" prop="zzId">
            <el-input v-model.trim="search3.zzId"
                      placeholder="请输入藏品编号(限50字)"
                      maxlength="50"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(3)" icon='el-icon-refresh-right'>重置</el-button>
            <el-button size="small" @click="exportData(1)"><i class="icon-piliangdaochu iconfont buIcon"/>
              批量导出
            </el-button>
          </el-form-item>
        </el-form>
        <div style="margin-left: 25px">统计{{ search3.total }}件藏品</div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="margin-left: 20px"
            v-loading="search3.loading"
            height="calc(100vh - 330px)"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            ref="multipleTable3"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button v-show="scope.row.identified == '否'" style="margin-bottom: 10px" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">新增鉴定结果
              </el-button>
              <el-button v-show="scope.row.identified == '是'" style="margin-bottom: 10px" size="mini"
                         type="text"
                         @click="appraisalResults(scope.row)">查看鉴定结果
              </el-button>
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="detail(scope.row)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="search3.current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search3.size"
              :total="search3.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>

      <!--            <div v-show="selectTab == '4'">-->
      <!--                <div style="margin-left: 25px">已选{{ immovableList.length }}件藏品</div>-->
      <!--                <el-form size="small" ref="inputForm" inline :model="search4" label-width="100px">-->
      <!--                    <el-form-item label="文物名称" prop="zzName">-->
      <!--                        <el-input v-model.trim="search4.zzName"-->
      <!--                                  placeholder="请输入文物名称(限50字)"-->
      <!--                                  maxlength="50" clearable></el-input>-->
      <!--                    </el-form-item>-->
      <!--                    <el-form-item label-width="0">-->
      <!--                        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">-->
      <!--                            查询-->
      <!--                        </el-button>-->
      <!--                        <el-button size="small" @click="reseting(4)" icon='el-icon-refresh-right'>重置</el-button>-->
      <!--                        <el-button size="small" @click="exportData(1)">导出</el-button>-->
      <!--                    </el-form-item>-->
      <!--                </el-form>-->
      <!--                <el-table-->
      <!--                        :data="immovableList"-->
      <!--                        size="small"-->
      <!--                        style="margin-left: 20px"-->
      <!--                        v-loading="search4.loading"-->
      <!--                        height="calc(100vh - 330px)"-->
      <!--                        class="table"-->
      <!--                        :row-key="'id'"-->
      <!--                        :header-cell-style="{background:'#F9DFDF'}"-->
      <!--                        ref="multipleTable4"-->
      <!--                        @selection-change="selectionChangeHandle"-->
      <!--                >-->
      <!--                    <el-table-column :reserve-selection="true" type="selection" width="50"/>-->
      <!--                    <el-table-column label="序号" type="index" width="50"></el-table-column>-->
      <!--                    <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>-->
      <!--                        <template slot-scope="scope">-->
      <!--                            {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column prop="name" label="图标" width="120px">-->
      <!--                        <template slot-scope="scope">-->
      <!--                            <div class="flex_a_c">-->
      <!--                                <div v-if="scope.row.carded == 0"-->
      <!--                                     class="icon-wenwukapian iconfont listIcon"></div>-->
      <!--                                <el-image-->
      <!--                                        style="width: 36px; height: 36px"-->
      <!--                                        :src="scope.row.picMasterViewUrl"-->
      <!--                                        :fit="'cover'"-->
      <!--                                        :preview-src-list="[scope.row.picMasterViewUrl]"-->
      <!--                                >-->
      <!--                                    <div slot="error" class="image-slot">-->
      <!--                                        <el-image-->
      <!--                                                style="width: 36px; height: 36px"-->
      <!--                                                :src="require('@/assets/img/default.png')"-->
      <!--                                                :fit="'cover'">-->
      <!--                                        </el-image>-->
      <!--                                    </div>-->
      <!--                                </el-image>-->
      <!--                            </div>-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column prop="name" label="文物名称"-->
      <!--                                     show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="culturalRelicsCategory" label="文物类别"-->
      <!--                                     show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>-->
      <!--                        <template slot-scope="scope">-->
      <!--                            {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>-->
      <!--                        <template slot-scope="scope">-->
      <!--                            {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column prop="createName" label="创建者" show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="createDate" sortable label="创建时间" width="150px">-->
      <!--                        <template slot-scope="scope">-->
      <!--                            {{ scope.row.createDate | formatDate }}-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column prop="updateName" label="最后更新人"-->
      <!--                                     show-overflow-tooltip></el-table-column>-->
      <!--                    <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"-->
      <!--                                     show-overflow-tooltip>-->
      <!--                        <template slot-scope="scope">-->
      <!--                            {{ scope.row.updateDate | formatDate }}-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                    <el-table-column fixed="right" label="操作" width="180">-->
      <!--                        <template slot-scope="scope">-->
      <!--                            <el-button style="margin-bottom: 10px" size="mini" type="primary" plain-->
      <!--                                       @click="appraisalResults(scope.row)">鉴定结果-->
      <!--                            </el-button>-->
      <!--                            <el-button style="margin-bottom: 10px" size="mini" type="primary" plain-->
      <!--                                       @click="detail(scope.row)">详情-->
      <!--                            </el-button>-->
      <!--                        </template>-->
      <!--                    </el-table-column>-->
      <!--                </el-table>-->
      <!--                <div class="text_center">-->
      <!--                    <el-pagination-->
      <!--                            @size-change="sizeChangeHandle"-->
      <!--                            @current-change="currentChangeHandle"-->
      <!--                            :current-page="search4.current"-->
      <!--                            :page-sizes="[10, 20, 50, 100]"-->
      <!--                            :page-size="search4.size"-->
      <!--                            :total="search4.total"-->
      <!--                            background-->
      <!--                            layout="total, sizes, prev, pager, next, jumper"-->
      <!--                    >-->
      <!--                    </el-pagination>-->
      <!--                </div>-->
      <!--            </div>-->


    </div>
    <div class="text_center">
      <el-button size="small" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button size="small" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>

    <appraisal-results ref="appraisalResults" @refresh="handleClick()"></appraisal-results>
  </div>
</template>

<script>
import AppraisalResults from "@/views/modules/collection/business/collIdentification/appraisalResults.vue";

export default {
  name: "inventoryDetail",
  components: {AppraisalResults},
  data() {
    return {
      appraisalId: '',
      selectTab: '999',
      tabsList: [
        {
          name: '基本信息',
          value: '999',
        },
        {
          name: '征集藏品',
          value: '1',
        },
        {
          name: '总账藏品',
          value: '2',
        },
        {
          name: '辅助账藏品',
          value: '3',
        },
        // {
        //     name: '不可移动文物',
        //     value: '4',
        // },
      ],

      inputForm: {
        appraisalDate: '',
        appraisalInstitution: '',
        agent: '',
        collectionAppraisalExpert: '',
        dynamicTags: [],
        appraisalType: '',
        appraisalTypes:[],
        appraisalMethod: '',
        reason: '',
        remark: '',
      },

      rules: {
        appraisalDate: [
          {required: true, message: "请选择鉴定时间", trigger: "blur"},
        ],
        collectionAppraisalExpert: [
          {required: true, message: "请输入专家姓名", trigger: "blur"},
        ],
      },

      //征集
      search1: {
        zzName: '',
        type: 0,
        zzId: '',
        current: 1,
        size: 10,
        appraisalId: this.$route.query.appraisalId,
        loading: false,
        total: 0
      },
      solicitationList: [],
      dataListSelect1: [],

      //总账藏品清单
      search2: {
        zzName: '',
        zzId: '',
        type: 1,
        current: 1,
        size: 10,
        total: 0,
        appraisalId: this.$route.query.appraisalId,
        loading: false,
      },
      generalList: [],
      dataListSelect2: [],

      //辅助账藏品清单
      search3: {
        zzName: '',
        zzId: '',
        current: 1,
        type: 2,
        size: 10,
        total: 0,
        appraisalId: this.$route.query.appraisalId,
        loading: false,
      },
      auxiliaryList: [],
      dataListSelect3: [],

      //不可移动文物
      search4: {
        zzName: '',
        current: 1,
        size: 10,
        total: 0,
        appraisalId: this.$route.query.appraisalId,
        loading: false,
      },
      immovableList: [],
      dataListSelect4: [],

      expertNameLoading: false,
      expertNameList: [],

      inputType: true,
      returnPageStatus: '',
      // butType: "", //0新增 1详情 2修改 4审核
      leaveList: [],

      inputVisible: false,
      registrationSearch: {},
    }
  },

  mounted() {
    this.remoteMethod()
    this.appraisalId = this.$route.query.appraisalId
    if (this.appraisalId) {
      this.getRowDetail()
    }
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })

    let listSearch = JSON.parse(sessionStorage.getItem('listSearch2'))
    console.log(listSearch)
    if (listSearch) {
      this.selectTab = listSearch.selectTab
      this.getDataList()
      sessionStorage.removeItem('listSearch2')
    }

  },

  methods: {
    getRowDetail() {
      this.$axios(this.api.collection.getInventoryById + this.appraisalId, {}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          if (this.inputForm.outerExpertName.split(',')) {
            this.inputForm.dynamicTags = this.inputForm.outerExpertName.split(',')
          } else {
            this.inputForm.dynamicTags = [this.inputForm.outerExpertName]
          }
          if (this.inputForm.appraisalType.split(',')) {
            this.inputForm.appraisalTypes = this.inputForm.appraisalType.split(',')
          } else {
            this.inputForm.appraisalTypes = [this.inputForm.appraisalType]
          }
          this.inputForm.collectionAppraisalExpert = this.inputForm.collectionAppraisalExpertVOList.map(item => {
            return item.expertId
          })

          this.search1.current = 1
          this.search2.current = 1
          this.search3.current = 1
          this.search4.current = 1
        }
      })
    },

    handleClick(tab, event) {
      this.getDataList()
    },

    getDataList(num, type) {
      if (num == 1) {
        this['search' + this.selectTab].current = 1
      }
      if (type) {
        this.$refs['multipleTable' + this.selectTab].clearSelection()
      }
      let data = this['search' + this.selectTab]
      data.appraisalId = this.appraisalId

      let fechUrl = this.api.collection.collectionappraisalQueryCollection
      if (this.selectTab == 4) {
        fechUrl = this.api.collection.collectionImmovableCulturalRelicSelectListByIds
      }

      this['search' + this.selectTab].loading = true;
      this.$axios(fechUrl, data, 'get').then(data => {
        if (data.status) {
          if (this.selectTab == 1) {
            this.solicitationList = data.data.records
          }
          if (this.selectTab == 2) {
            this.generalList = data.data.records
          }
          if (this.selectTab == 3) {
            this.auxiliaryList = data.data.records
          }
          if (this.selectTab == 4) {
            this.immovableList = data.data.records
          }
          this['search' + this.selectTab].total = parseInt(data.data.total)
          this['search' + this.selectTab].loading = false;
        }
      })
    },

    //鉴定结果
    appraisalResults(row) {
      this.$refs.appraisalResults.init(row,this.appraisalId, this.selectTab)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this['dataListSelect' + this.selectTab] = val
    },

    //详情
    detail(row) {
      let listSearch = {
        selectTab: this.selectTab,
      }
      sessionStorage.setItem('listSearch2', JSON.stringify(listSearch))
      if (this.selectTab == 1) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 8,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'museum',
            see: 1
          }
        })
      }
      if (this.selectTab == 2) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'ledger',
            see: 1
          }
        })
      }
      if (this.selectTab == 3) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'auxiliaryAccount',
            see: 1
          }
        })
      }
      if (this.selectTab == 4) {
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: 1, id: row.id, typePage: 'manage', see: true}
        })
      }
    },

    reseting(num) {
      if (num == 1) {
        this.search1.zzName = ''
        this.search1.zzId = ''
      }
      if (num == 2) {
        this.search2.zzName = ''
        this.search2.zzId = ''
      }
      if (num == 3) {
        this.search3.zzName = ''
        this.search3.zzId = ''
      }
      if (num == 4) {
        this.search4.zzName = ''
      }
      this.getDataList(1, 1)
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    //边打边搜
    remoteMethod(query) {
      this.expertNameLoading = true;
      this.$axios(this.api.collection.appraisalexpertQueryList, {expertName: query}, 'get').then(data => {
        if (data.status) {
          this.expertNameList = data.data
          this.expertNameLoading = false;
        }
      })
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      this.$router.back()
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this['search' + this.selectTab].size = val
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this['search' + this.selectTab].current = val
      this.getDataList();
    },

    //导出
    exportData() {
      if (this['dataListSelect' + this.selectTab].length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this['dataListSelect' + this.selectTab].map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        appraisalId:this.appraisalId,
        ids: ids,
      }
      // this.exportExcel(this.api.collection.collectionappraisalresultExport, data, '藏品列表', 'get')
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.collectionappraisalresultExport, data, 'post', 'blob').then(res => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        // let filename = res.headers['content-disposition']
        let filename = '藏品鉴定' + this.inputForm.appraisalDate;
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
      this.getDataList('', 1)
    },

    handleClose(tag) {
      this.$confirm(`您是否确认删除该专家?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.dynamicTags.splice(this.inputForm.dynamicTags.indexOf(tag), 1);
      })
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      let reapet = this.inputForm.dynamicTags.filter(item => {
        return item == inputValue
      })
      if (reapet.length != 0) {
        this.$message.error('您已经添加过改专家了')
        this.inputVisible = false;
        this.inputValue = '';
        return
      }
      if (inputValue) {
        this.inputForm.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },


    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current == 1) {
          this.$message.error('已是第一条')
        } else {
          this.registrationSearch.current--
        }
      }
      this.$axios(this.api.collection.collectionappraisalList, this.registrationSearch, 'get').then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.appraisalId = res.data.records[0].appraisalId
            this.getRowDetail()
            if (this.selectTab != 99) {
              this.reseting(this.selectTab)
            }
          } else {
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
            this.registrationSearch.current--
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    next();
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.contens {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}
</style>
